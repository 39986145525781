import identity from 'lodash/identity';
import { object, string } from 'prop-types';
import React from 'react';
import { FieldLocationAutocompleteInput, FieldRadioButton } from '../../../components';
import { SCHEMA_TYPE_ENUM, SCHEMA_TYPE_LOCATION } from '../../../util/types';
import {
  autocompletePlaceSelected,
  autocompletePlaceSelectedIfSearchIsValid,
  autocompleteSearchRequired,
  composeValidators,
  required,
} from '../../../util/validators';

import css from './ModalCreateListing.module.css';

const CustomFieldEnum = props => {
  const { name, fieldConfig } = props;
  const { enumOptions = [], saveConfig } = fieldConfig ?? {};
  const { isRequired, requiredMessage } = saveConfig ?? {};
  const validateMaybe = isRequired ? { validate: required(requiredMessage) } : {};

  return (
    <div className={css.customField}>
      {enumOptions.map(({ option, label }) => (
        <FieldRadioButton
          id={`${name}-${option}`}
          name={name}
          label={label}
          value={option}
          showAsRequired={isRequired}
          {...validateMaybe}
        />
      ))}
    </div>
  );
};

const CustomFieldLocationAutocompleteInput = props => {
  const { name, fieldConfig } = props;
  const {
    placeholderMessage,
    isRequired,
    requiredMessage,
    searchRequiredMessage,
    placeRequiredMessage,
  } = fieldConfig?.saveConfig || {};

  const validate = isRequired
    ? composeValidators(
        autocompleteSearchRequired(searchRequiredMessage ?? requiredMessage),
        autocompletePlaceSelected(placeRequiredMessage ?? requiredMessage)
      )
    : autocompletePlaceSelectedIfSearchIsValid(placeRequiredMessage ?? requiredMessage);

  return (
    <FieldLocationAutocompleteInput
      rootClassName={css.customField}
      iconClassName={css.customLocationAutocompleteInputIcon}
      predictionsClassName={css.customPredictionsRoot}
      id={name}
      name={name}
      placeholder={placeholderMessage}
      useDefaultPredictions={false}
      format={identity}
      validate={validate}
    />
  );
};

const schemaConfig = {
  [SCHEMA_TYPE_ENUM]: CustomFieldEnum,
  [SCHEMA_TYPE_LOCATION]: CustomFieldLocationAutocompleteInput,
};

const CustomExtendedDataField = props => {
  const { fieldConfig } = props;
  const { schemaType } = fieldConfig || {};

  const FieldComponent = schemaConfig[schemaType];
  return FieldComponent ? <FieldComponent {...props} /> : null;
};

CustomExtendedDataField.propTypes = {
  name: string.isRequired,
  fieldConfig: object.isRequired,
};

export default CustomExtendedDataField;
