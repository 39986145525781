import { any, node, string } from 'prop-types';
import React from 'react';

import css from './ModalCreateListing.module.css';

const CreateListingWizardPage = props => {
  const { title, subTitle, footNote, children: fields } = props;

  return (
    <div className={css.wizardPage}>
      <div className={css.header}>
        <div className={css.wizardTitle}>{title}</div>
        {subTitle ? <div className={css.wizardSubTitle}>{subTitle}</div> : null}
      </div>
      {fields}
      {footNote ? <div className={css.footNote}>{footNote}</div> : null}
    </div>
  );
};

CreateListingWizardPage.propTypes = {
  title: string.isRequired,
  subTitle: string,
  footNote: any,
  children: node.isRequired,
};

export default CreateListingWizardPage;
