import { func } from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { FormWizard } from '../../../components';
import { useConfiguration } from '../../../context/configurationContext';
import EmailFieldWizardPage from './EmailFieldWizardPage';
import ListingFieldWizardPage from './ListingFieldWizardPage';
import MatchFoundWizardPage from './MatchFoundWizardPage';
import NameFieldWizardPage from './NameFieldWizardPage';
import PhoneNumberFieldWizardPage from './PhoneNumberFieldWizardPage';

const FormCreateListing = props => {
  const { onSubmit } = props;
  const intl = useIntl();
  const {
    createCustomerAndListingInProgress,
    createCustomerError,
    createListingError,
  } = useSelector(state => state.LeadLandingPage);

  const errorMessageId = createCustomerError
    ? 'ModalCreateListing.createCustomerError'
    : createListingError
    ? 'ModalCreateListing.createListingError'
    : null;
  const errorMessage = errorMessageId ? intl.formatMessage({ id: errorMessageId }) : null;

  const { listing: listingConfig } = useConfiguration();
  const popupListingFields = listingConfig.listingFields.filter(
    fieldConfig => fieldConfig.saveConfig.showInPopup
  );

  return (
    <FormWizard
      onSubmit={onSubmit}
      submitInProgress={createCustomerAndListingInProgress}
      errorText={errorMessage}
    >
      {popupListingFields.map(fieldConfig => (
        <ListingFieldWizardPage key={fieldConfig.key} fieldConfig={fieldConfig} />
      ))}
      <MatchFoundWizardPage />
      <EmailFieldWizardPage />
      <PhoneNumberFieldWizardPage />
      <NameFieldWizardPage />
    </FormWizard>
  );
};

FormCreateListing.propTypes = {
  onSubmit: func.isRequired,
};

export default FormCreateListing;
