import React from 'react';
import { partnerItems } from '../LeadLandingPage.constant';

import css from './SectionPartners.module.css';

const SectionPartners = props => {
  return (
    <div className={css.root}>
      {partnerItems.map(item => (
        <img key={item.key} src={item.image} alt={item.alt} />
      ))}
    </div>
  );
};

export default SectionPartners;
