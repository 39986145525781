import { object } from 'prop-types';
import React from 'react';
import CreateListingWizardPage from './CreateListingWizardPage';
import CustomExtendedDataField from './CustomExtendedDataField';

const ListingFieldWizardPage = props => {
  const { fieldConfig } = props;
  const {
    key,
    saveConfig: { label, subLabel, question },
  } = fieldConfig;

  return (
    <CreateListingWizardPage title={question ?? label} subTitle={subLabel}>
      <CustomExtendedDataField id={key} name={key} fieldConfig={fieldConfig} />
    </CreateListingWizardPage>
  );
};

ListingFieldWizardPage.propTypes = {
  fieldConfig: object.isRequired,
};

export default ListingFieldWizardPage;
