import { string } from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { Button } from '../../../components';
import { useCurrentResourceLocatorString, useNamedNavigate } from '../../../hooks/router';

import css from './ModalCreateListing.module.css';

const LoginOverlay = props => {
  const { email } = props;
  const currentResourceLocatorString = useCurrentResourceLocatorString();
  const { navigate } = useNamedNavigate();
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'ModalCreateListing.errorAccountExistsTitle' });
  const subTitle = intl.formatMessage({ id: 'ModalCreateListing.errorAccountExistsSubTitle' });
  const loginLabel = intl.formatMessage({ id: 'ModalCreateListing.loginLabel' });

  const handleLogin = () =>
    navigate('LoginPage', {}, { email }, { from: currentResourceLocatorString });

  return (
    <div className={css.wizardPage}>
      <div className={css.wizardTitle}>{title}</div>
      <div className={css.wizardSubTitle}>{subTitle}</div>
      <Button className={css.loginButton} onClick={handleLogin}>
        {loginLabel}
      </Button>
    </div>
  );
};

LoginOverlay.propTypes = {
  email: string.isRequired,
};

export default LoginOverlay;
