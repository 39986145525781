import React from 'react';
import { useIntl } from 'react-intl';
import { FieldPhoneNumberInput } from '../../../components';
import { required } from '../../../util/validators';
import CreateListingWizardPage from './CreateListingWizardPage';

const PhoneNumberFieldWizardPage = props => {
  const intl = useIntl();
  const phoneNumberTitle = intl.formatMessage({ id: 'ModalCreateListing.phoneNumberTitle' });
  const phoneNumberPlaceholder = intl.formatMessage({
    id: 'ModalCreateListing.phoneNumberPlaceholder',
  });
  const phoneNumberRequired = intl.formatMessage({ id: 'ModalCreateListing.phoneNumberRequired' });
  const phoneNumberValidator = required(phoneNumberRequired);

  return (
    <CreateListingWizardPage title={phoneNumberTitle}>
      <FieldPhoneNumberInput
        id="phoneNumber"
        name="phoneNumber"
        type="tel"
        autoComplete="tel"
        placeholder={phoneNumberPlaceholder}
        validate={phoneNumberValidator}
      />
    </CreateListingWizardPage>
  );
};

export default PhoneNumberFieldWizardPage;
