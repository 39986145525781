import React, { useEffect, useState } from 'react';
import { func, string } from 'prop-types';
import { FormattedMessage } from '../../../util/reactIntl';
import { Button, NamedLink } from '../../../components';
import classNames from 'classnames';

import css from './SectionHero.module.css';

const SectionHero = props => {
  const { rootClassName, className, onCreateListing } = props;

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <h1 className={classNames(css.heroMainTitle, { [css.heroMainTitleFEDelay]: mounted })}>
          <FormattedMessage id="LeadLandingPage.SectionHero.title" />
        </h1>
        <h2 className={classNames(css.heroSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
          <FormattedMessage id="LeadLandingPage.SectionHero.subtitle" />
        </h2>
        <Button className={css.heroButton} onClick={onCreateListing}>
          <FormattedMessage id="LeadLandingPage.SectionHero.createLeadButton" />
        </Button>
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
  onCreateListing: func.isRequired,
};

export default SectionHero;
