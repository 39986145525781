import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '../../../components';
import { manageDisableScrolling } from '../../../ducks/ui.duck';
import { useNamedNavigate } from '../../../hooks/router';
import { ROLE_CUSTOMER } from '../../../util/data';
import { createSlug } from '../../../util/urlHelpers';
import { requestCreateCustomerAndListing, requestCreateListing } from '../LeadLandingPage.duck';
import FormCreateListing from './FormCreateListing';
import LoginOverlay from './LoginOverlay';

const LISTING_DATA_KEY = 'listing_data';

const ModalCreateListing = props => {
  const { isOpen, onClose } = props;
  const dispatch = useDispatch();
  const { navigate } = useNamedNavigate();
  const { currentUser, currentUserHasListings } = useSelector(state => state.user);
  const [registeredEmail, setRegisteredEmail] = useState(null);

  const onManageDisableScrolling = (...params) => dispatch(manageDisableScrolling(...params));
  const createCustomerAndListing = (userInfo, listingData) =>
    dispatch(requestCreateCustomerAndListing(userInfo, listingData));
  const createListing = listingData => dispatch(requestCreateListing(listingData));

  const navigateAfterCreateListing = listing => {
    if (currentUserHasListings) {
      navigate('ManageListingsPage');
      return;
    }

    const { id: { uuid: listingUuid } = {}, attributes: { title } = {} } = listing ?? {};
    navigate('ListingPage', { id: listingUuid, slug: createSlug(title) });
  };

  useEffect(() => {
    const serializedData = localStorage.getItem(LISTING_DATA_KEY);
    if (!serializedData || !currentUser) return;

    try {
      const { email, phoneNumber, listingData } = JSON.parse(serializedData);
      const {
        email: userEmail,
        profile: {
          protectedData: { phoneNumber: phoneNumberInUserData },
          metadata: { roles },
        },
      } = currentUser.attributes ?? {};

      const isCustomer = roles?.includes(ROLE_CUSTOMER);
      const isSameUser = userEmail === email;
      if (!isCustomer || !isSameUser) return;

      const phoneNumberMaybe = phoneNumber !== phoneNumberInUserData ? { phoneNumber } : {};
      createListing({ ...listingData, ...phoneNumberMaybe }).then(listing =>
        navigateAfterCreateListing(listing)
      );
    } finally {
      localStorage.removeItem(LISTING_DATA_KEY);
    }
  }, []);

  const handleSubmit = values => {
    const { email, phoneNumber, firstName, lastName, ...listingData } = values;
    const userInfo = {
      email,
      phoneNumber,
      firstName: firstName.trim(),
      lastName: lastName.trim(),
    };

    createCustomerAndListing(userInfo, listingData)
      .then(listing => navigateAfterCreateListing(listing))
      .catch(e => {
        if (e.status !== 409) return;

        localStorage.setItem(LISTING_DATA_KEY, JSON.stringify({ email, phoneNumber, listingData }));
        setRegisteredEmail(email);
      });
  };

  const modalContent = registeredEmail ? (
    <LoginOverlay email={registeredEmail} />
  ) : (
    <FormCreateListing onSubmit={handleSubmit} />
  );

  return (
    <Modal
      id="ModalCreateListing"
      isOpen={isOpen}
      onClose={onClose}
      closeButtonMessage=" "
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
    >
      {modalContent}
    </Modal>
  );
};

export default ModalCreateListing;
