import React from 'react';
import { useIntl } from 'react-intl';
import { FieldTextInput, NamedLink } from '../../../components';
import { required } from '../../../util/validators';
import CreateListingWizardPage from './CreateListingWizardPage';

import css from './ModalCreateListing.module.css';

const NameFieldWizardPage = props => {
  const intl = useIntl();

  const nameTitle = intl.formatMessage({
    id: 'ModalCreateListing.nameTitle',
  });
  const termsOfServiceMessage = intl.formatMessage({
    id: 'ModalCreateListing.termsOfService',
  });
  const privacyPolicyMessage = intl.formatMessage({
    id: 'ModalCreateListing.privacyPolicy',
  });
  const termsOfService = (
    <NamedLink name="CMSPage" params={{ pageId: 'terms-of-service' }} target="_blank">
      {termsOfServiceMessage}
    </NamedLink>
  );
  const privacyPolicy = (
    <NamedLink name="CMSPage" params={{ pageId: 'privacy-policy' }} target="_blank">
      {privacyPolicyMessage}
    </NamedLink>
  );
  const nameFootnote = intl.formatMessage(
    {
      id: 'ModalCreateListing.nameFootnote',
    },
    {
      termsOfService,
      privacyPolicy,
    }
  );

  const firstNameLabel = intl.formatMessage({
    id: 'ModalCreateListing.firstNameLabel',
  });
  const firstNamePlaceholder = intl.formatMessage({
    id: 'ModalCreateListing.firstNamePlaceholder',
  });
  const firstNameRequired = intl.formatMessage({
    id: 'ModalCreateListing.firstNameRequired',
  });
  const firstNameValidator = required(firstNameRequired);

  const lastNameLabel = intl.formatMessage({
    id: 'ModalCreateListing.lastNameLabel',
  });
  const lastNamePlaceholder = intl.formatMessage({
    id: 'ModalCreateListing.lastNamePlaceholder',
  });
  const lastNameRequired = intl.formatMessage({
    id: 'ModalCreateListing.lastNameRequired',
  });
  const lastNameValidator = required(lastNameRequired);

  return (
    <CreateListingWizardPage title={nameTitle} footNote={nameFootnote}>
      <div className={css.nameContainer}>
        <FieldTextInput
          className={css.firstName}
          id="firstName"
          name="firstName"
          type="text"
          autoComplete="given-name"
          label={firstNameLabel}
          placeholder={firstNamePlaceholder}
          validate={firstNameValidator}
        />
        <FieldTextInput
          className={css.lastName}
          id="lastName"
          name="lastName"
          type="text"
          autoComplete="family-name"
          label={lastNameLabel}
          placeholder={lastNamePlaceholder}
          validate={lastNameValidator}
        />
      </div>
    </CreateListingWizardPage>
  );
};

export default NameFieldWizardPage;
