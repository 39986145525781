import React from 'react';
import { any, arrayOf, shape, string } from 'prop-types';
import classNames from 'classnames';
import { H2, H4, NamedLink } from '../../../components';
import { FormattedMessage } from 'react-intl';
import { stringify } from '../../../util/urlHelpers';

import css from './SectionJobTypes.module.css';

const SectionJobTypes = props => {
  const { rootClassName, className, items } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <H2>
        <FormattedMessage id="LeadLandingPage.SectionJobTypes.title" />
      </H2>

      <div className={css.typesOfJob}>
        {items.map(item => {
          return (
            <NamedLink key={item.key} name="NewListingPage" className={css.item}>
              <img src={item.image} alt={item.key} />
              <div className={css.itemTitle}>{item.label}</div>
            </NamedLink>
          );
        })}
      </div>
    </div>
  );
};

SectionJobTypes.defaultProps = { rootClassName: null, className: null, name: null };

SectionJobTypes.propTypes = {
  rootClassName: string,
  className: string,
  name: string,
  items: arrayOf(
    shape({
      label: string,
      key: string,
      image: any,
    })
  ).isRequired,
};

export default SectionJobTypes;
