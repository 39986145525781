import React from 'react';
import { useIntl } from 'react-intl';
import { IconAnimatedCheckmark } from '../../../components';

import css from './ModalCreateListing.module.css';

const MatchFoundWizardPage = props => {
  const intl = useIntl();

  const matchFoundTitle = intl.formatMessage({
    id: 'ModalCreateListing.matchFoundTitle',
  });
  const matchFoundSubTitle = intl.formatMessage({
    id: 'ModalCreateListing.matchFoundSubTitle',
  });

  return (
    <div className={css.wizardPage}>
      <IconAnimatedCheckmark className={css.checkmark} />
      <div className={css.animatedHeader}>
        <div className={css.wizardTitle}>{matchFoundTitle}</div>
        <div className={css.wizardSubTitle}>{matchFoundSubTitle}</div>
      </div>
    </div>
  );
};

export default MatchFoundWizardPage;
