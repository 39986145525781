import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';
import { useRouteConfiguration } from '../context/routeConfigurationContext';
import { createResourceLocatorString } from '../util/routes';

export const useNamedNavigate = () => {
  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();

  const navigate = (name, pathParams = {}, searchParams = {}, state = {}) => {
    const resourceLocatorString = createResourceLocatorString(
      name,
      routeConfiguration,
      pathParams,
      searchParams
    );
    history.push(resourceLocatorString, state);
  };

  const redirect = (name, pathParams = {}, searchParams = {}, state = {}) => {
    const resourceLocatorString = createResourceLocatorString(
      name,
      routeConfiguration,
      pathParams,
      searchParams
    );
    history.replace(resourceLocatorString, state);
  };

  return { navigate, redirect };
};

export const useCurrentResourceLocatorString = () => {
  const { pathname, search, hash } = useLocation();

  return useMemo(() => `${pathname}${search}${hash}`, [pathname, search, hash]);
};

export const useSearchParams = () => {
  const history = useHistory();
  const { search, pathname, state } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const setSearchParams = (paramsUpdateFn, forceUpdate = true) => {
    const params =
      typeof paramsUpdateFn === 'function'
        ? paramsUpdateFn(new URLSearchParams(searchParams))
        : paramsUpdateFn;

    if (!forceUpdate && searchParams.toString() === params.toString()) return;
    history.push(`${pathname}?${params.toString()}`, state);
  };
  const setSearchParam = (key, value) =>
    setSearchParams(params => {
      params.set(key, value);
      return params;
    });
  const deleteSearchParam = key =>
    setSearchParams(params => {
      params.delete(key);
      return params;
    }, false);
  const resetSearchParams = () => setSearchParams(new URLSearchParams());

  return {
    searchParams,
    setSearchParams,
    setSearchParam,
    deleteSearchParam,
    resetSearchParams,
  };
};

export const useSearchParam = key => {
  const { searchParams, setSearchParam, deleteSearchParam } = useSearchParams();

  const value = searchParams.get(key);
  const clearValue = () => deleteSearchParam(key);
  const setValue = value => {
    if (!value) return clearValue();
    setSearchParam(key, value);
  };

  return {
    value,
    setValue,
    clearValue,
  };
};
