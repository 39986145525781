import React from 'react';
import { useIntl } from 'react-intl';
import { FieldTextInput } from '../../../components';
import { composeValidators, emailFormatValid, required } from '../../../util/validators';
import CreateListingWizardPage from './CreateListingWizardPage';

const EmailFieldWizardPage = props => {
  const intl = useIntl();
  const emailTitle = intl.formatMessage({
    id: 'ModalCreateListing.emailTitle',
  });
  const emailPlaceholder = intl.formatMessage({
    id: 'ModalCreateListing.emailPlaceholder',
  });
  const emailRequired = intl.formatMessage({
    id: 'ModalCreateListing.emailRequired',
  });
  const emailInvalid = intl.formatMessage({
    id: 'ModalCreateListing.emailInvalid',
  });
  const emailValidator = composeValidators(required(emailRequired), emailFormatValid(emailInvalid));

  return (
    <CreateListingWizardPage title={emailTitle}>
      <FieldTextInput
        id="email"
        name="email"
        type="email"
        autoComplete="email"
        placeholder={emailPlaceholder}
        validate={emailValidator}
      />
    </CreateListingWizardPage>
  );
};

export default EmailFieldWizardPage;
