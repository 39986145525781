//Partner logos
import abcLogo from '../../assets/landing/abc_australia_logo.png';
import businessInsiderLogo from '../../assets/landing/business_insider_logo.png';
import dailyMailLogo from '../../assets/landing/daily_mail.png';
import newsComAuLogo from '../../assets/landing/news_com_au.png';
import smartCompanyLogo from '../../assets/landing/smart_company_logo.png';

//Job type images
import consultationImage from '../../assets/landing/get_consultation.jpg';
import sendLegalClaimImage from '../../assets/landing/send_legal_claim.jpg';
import fileLegalClaimImage from '../../assets/landing/file_legal_claim.jpg';
import defendLegalClaimImage from '../../assets/landing/defend_legal_claim.jpg';
import representationImage from '../../assets/landing/representation.jpg';
import publicNotaryImage from '../../assets/landing/public_notary.jpg';

//testimonial images
import jessicaImage from '../../assets/landing/testimonial_jessica.jpg';
import robertImage from '../../assets/landing/testimonial_robert.jpg';
import lisaImage from '../../assets/landing/testimonial_lisa.jpg';

export const partnerItems = [
  {
    key: 'abc-australia',
    alt: 'Logo of ABC Australia',
    image: abcLogo,
  },
  {
    key: 'business-insider',
    alt: 'Logo of Business Insider',
    image: businessInsiderLogo,
  },
  {
    key: 'daily-mail',
    alt: 'Logo of Daily Mail Australia',
    image: dailyMailLogo,
  },
  {
    key: 'news-com-au',
    alt: 'Logo of News com au',
    image: newsComAuLogo,
  },
  {
    key: 'smart-company',
    alt: 'Logo of Smart Company',
    image: smartCompanyLogo,
  },
];

export const jobTypeItems = [
  {
    label: 'Get consultation and advice',
    key: 'get-consultation-and-advice',
    image: consultationImage,
  },
  {
    label: 'Send a legal letter',
    key: 'send-a-legal-letter',
    image: sendLegalClaimImage,
  },
  {
    label: 'File a legal claim',
    key: 'file-a-legal-claim',
    image: fileLegalClaimImage,
  },
  {
    label: 'Defend a legal claim',
    key: 'defend-a-legal-claim',
    image: defendLegalClaimImage,
  },
  {
    label: 'Representation at court',
    key: 'representation-at-court',
    image: representationImage,
  },
  {
    label: 'Notary public services',
    key: 'notary-public-services',
    image: publicNotaryImage,
  },
];

export const testimonialItems = [
  {
    key: 'jessica-k',
    content:
      'I was looking for a property lawyer on the platform. The lawyers responded very promptly with offers and was able to review my contracts on the same day.',
    author: 'Jessica K',
    job: '',
    image: jessicaImage,
  },
  {
    key: 'robert-w',
    content:
      'I urgently needed to find a lawyer to represent me at a court hearing. Legal Finda was really useful for finding a specialized lawyer who could help me and great for comparing pricing for my case.',
    author: 'Robert W',
    job: '',
    image: robertImage,
  },
  {
    key: 'lisa-b',
    content:
      'I had issues with settling a property I was purchasing due to defects and my previously lawyer was not helpful at all in understanding my situation and helping me. I got on Legal Finda and was able to find another lawyer who helped me pursue my rights under the purchase agreement and successfully settled the property!',
    author: 'Lisa B',
    job: '',
    image: lisaImage,
  },
];
