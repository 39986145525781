import loadable from '@loadable/component';
import { bool } from 'prop-types';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { LayoutSingleColumn, Page } from '../../components';
import { facebookImageURL, twitterImageURL } from '../../config/configBranding';
import { useConfiguration } from '../../context/configurationContext';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import { useNamedNavigate } from '../../hooks/router';
import { ROLE_CUSTOMER } from '../../util/data';
import FooterComponent from '../FooterContainer/FooterContainer';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import { jobTypeItems, testimonialItems } from './LeadLandingPage.constant';
import ModalCreateListing from './ModalCreateListing/ModalCreateListing';
import SectionHero from './SectionHero/SectionHero';
import SectionHowItWorks from './SectionHowItWorks/SectionHowItWorks';
import SectionJobTypes from './SectionJobTypes/SectionJobTypes';
import SectionPartners from './SectionPartners/SectionPartners';

import css from './LeadLandingPage.module.css';

const SectionTestimonials = loadable(() => import('./SectionTestimonials/SectionTestimonials'), {
  ssr: false,
});

const LeadLandingPage = props => {
  const { scrollingDisabled } = props;

  const intl = useIntl();
  const config = useConfiguration();
  const { navigate } = useNamedNavigate();
  const currentUser = useSelector(state => state.user.currentUser);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const handleOpenModal = () => setModalIsOpen(true);
  const handleCloseModal = () => setModalIsOpen(false);

  const schemaTitle = intl.formatMessage(
    { id: 'LeadLandingPage.schemaTitle' },
    { siteTitle: config.marketplaceName }
  );
  const schemaDescription = intl.formatMessage({
    id: 'LeadLandingPage.schemaDescription',
  });
  const schemaImage = `${facebookImageURL}`;

  const handleCreateListing = () => {
    if (currentUser) {
      navigate('NewListingPage');
    } else {
      handleOpenModal();
    }
    return true;
  };

  return (
    <Page
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImageURL, width: 1200, height: 630 }]}
      twitterImages={[{ url: twitterImageURL, width: 600, height: 314 }]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn
        topbar={<TopbarContainer role={ROLE_CUSTOMER} onCreateListing={handleCreateListing} />}
        footer={<FooterComponent />}
      >
        <div className={css.content}>
          <SectionHero onCreateListing={handleCreateListing} />
          <SectionHowItWorks />
          <SectionJobTypes items={jobTypeItems} />
          <SectionPartners />
          <SectionTestimonials items={testimonialItems} />
          <ModalCreateListing isOpen={modalIsOpen} onClose={handleCloseModal} />
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

LeadLandingPage.propTypes = {
  scrollingDisabled: bool.isRequired,
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

export default connect(
  mapStateToProps,
  null
)(LeadLandingPage);
