import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { H2, H4 } from '../../../components';
import { FormattedMessage } from 'react-intl';

import css from './SectionHowItWorks.module.css';

const SectionHowItWorks = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <H2 className={css.title}>
        <FormattedMessage id="LeadLandingPage.SectionHowItWorks.title" />
      </H2>
      <div className={css.sectionHowItWorks}>
        <div className={css.item}>
          <H4 className={css.heading}>
            <FormattedMessage id="LeadLandingPage.SectionHowItWorks.column1Title" />
          </H4>
          <p className={css.content}>
            <FormattedMessage id="LeadLandingPage.SectionHowItWorks.column1Content" />
          </p>
        </div>

        <div className={css.item}>
          <H4 className={css.heading}>
            <FormattedMessage id="LeadLandingPage.SectionHowItWorks.column2Title" />
          </H4>
          <p className={css.content}>
            <FormattedMessage id="LeadLandingPage.SectionHowItWorks.column2Content" />
          </p>
        </div>

        <div className={css.item}>
          <H4 className={css.heading}>
            <FormattedMessage id="LeadLandingPage.SectionHowItWorks.column3Title" />
          </H4>
          <p className={css.content}>
            <FormattedMessage
              id="LeadLandingPage.SectionHowItWorks.column3Content"
              values={{
                br: (
                  <>
                    <br />
                    <br />
                  </>
                ),
              }}
            />
          </p>
        </div>
      </div>
    </div>
  );
};

SectionHowItWorks.defaultProps = { rootClassName: null, className: null };

SectionHowItWorks.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHowItWorks;
